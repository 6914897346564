import crypto from 'crypto';

/**
 * Strip highlight markers from text
 */
export const cleanExcerpt = (text: string) =>
	text && text.replace(/@@@hl@@@/g, '').replace(/@@@endhl@@@/g, '');

// copied over from @confluence/hash
const hash = (algorithm: 'sha256' | 'sha1', data: any, asBytes: boolean = false) => {
	const hash = crypto.createHash(algorithm);
	hash.update(data);
	if (asBytes) {
		return Array.from(hash.digest());
	}
	return hash.digest('hex');
};

export const hashDataAsByte = (data: any) => {
	return hash('sha256', data, true) as number[];
};

export const hashData = (data: any) => {
	return hash('sha256', data) as string;
};

export const getUserPath = (
	userId: string,
	primaryProduct: string,
	orgId: string,
	cloudId: string,
) => {
	if (primaryProduct === 'confluence') {
		return `/wiki/people/${userId}`;
	} else if (primaryProduct === 'townsquare') {
		return `/o/${orgId}/people/${userId}?cloudId=${cloudId}`;
	}
};

export const getTeamPath = (
	teamId: string,
	primaryProduct: string,
	orgId: string,
	cloudId: string,
) => {
	if (primaryProduct === 'confluence') {
		return `/wiki/people/team/${teamId}`;
	} else if (primaryProduct === 'townsquare') {
		return `/o/${orgId}/people/team/${teamId}?cloudId=${cloudId}`;
	}
};
