import FeatureGates from '@atlaskit/feature-gate-js-client';

import { DEFAULT_STATSIG_EXPERIMENT_CONFIG } from '../constants';

import { getBackendExperimentConfig } from './backend';
import { getFrontendExperiment } from './frontend';

/**
 * This function fetches the experiment configuration within a given search experience layer
 * and will return the data for either the frontend experiment(s) or the backend experiment.
 *
 * If frontend experiment(s) exist in the layer,
 * * The frontend experiment exposure IS fired
 * * The backend experiment exposure IS NOT fired
 * * Backend experiment data IS NOT passed in requests
 *
 * If there is no frontend experiment(s) in the layer,
 * * The frontend experiment exposure IS NOT fired
 * * The backend experiment exposure IS fired
 * * Backend experiment data IS passed in requests
 */
export const getMutuallyExclusiveExperiment = (
	layerName?: string,
	onErrorFn?: (error: any) => any,
) => {
	try {
		if (!layerName) {
			return {
				frontendExperiment: undefined,
				backendExperiment: getBackendExperimentConfig(onErrorFn),
			};
		}

		const layer = FeatureGates.getLayer(layerName, { fireLayerExposure: false });
		const frontendExperimentName = layer.get('frontendExperimentName', '');

		if (frontendExperimentName) {
			return {
				frontendExperiment: getFrontendExperiment({
					abTestId: frontendExperimentName,
				}),
				backendExperiment: DEFAULT_STATSIG_EXPERIMENT_CONFIG,
			};
		}

		return {
			frontendExperiment: undefined,
			backendExperiment: getBackendExperimentConfig(onErrorFn),
		};
	} catch (error) {
		onErrorFn?.(error);
	}

	return {
		frontendExperiment: undefined,
		backendExperiment: DEFAULT_STATSIG_EXPERIMENT_CONFIG,
	};
};
