import FeatureGates from '@atlaskit/feature-gate-js-client';

import { Cohort, type FrontendExperimentProps, type FrontendExperiments } from '../../../types';

export const getFrontendExperiment = ({ abTestId }: FrontendExperimentProps) => {
	const cohort: string = FeatureGates.getExperiment(abTestId).get('cohort', Cohort.Default);
	const serveExperiment = cohort === Cohort.Test;
	return {
		serveExperiment,
		abTestId,
		cohort,
	};
};

export const serveFrontendExperiment = (
	abTestId: string,
	frontendExperiments?: FrontendExperiments,
) => {
	if (!frontendExperiments) {
		return false;
	}

	return Boolean(frontendExperiments[abTestId]?.serveExperiment);
};
