const ConfluencePageFragment = `
  fragment ConfluencePageFragment on ConfluencePage {
    __typename
    title
    pageStatus: status
    subtype
    ancestors {
      __typename
      id
      title
    }
    links {
      __typename
      base
      webUi
    }
    space {
      id
      name
    }
  }
`;

const ConfluenceBlogPostFragment = `
  fragment ConfluenceBlogPostFragment on ConfluenceBlogPost {
    __typename
    title
    blogStatus: status
    links {
      __typename
      base
      webUi
    }
    space {
      id
      name
    }
  }
`;

const ConfluenceWhiteboardFragment = `
  fragment ConfluenceWhiteboardFragment on ConfluenceWhiteboard {
    __typename
    title
    links {
      base
      webUi
    }
    space {
      id
      name
    }
  }
`;

const AtlasProjectFragment = `
  fragment AtlasProjectFragment on TownsquareProject {
    __typename
    key
    name
    url
    iconData
  }
`;

const AtlasGoalFragment = `
  fragment AtlasGoalFragment on TownsquareGoal {
    __typename
    key
    name
    url
    iconData
  }
`;

const JiraSingleIssueLineTextFieldFragment = `
  fragment JiraSingleIssueLineTextFieldFragment on JiraSingleLineTextField {
    text
  }
`;

const JiraIssueFragment = `
  fragment JiraIssueFragment on JiraIssue {
    __typename
    key
    webUrl
    fieldsById(ids: ["summary"]) {
      edges {
        node {
          __typename
          ...JiraSingleIssueLineTextFieldFragment
        }
      }
    }
  }
  ${JiraSingleIssueLineTextFieldFragment}
`;

export const operationName = 'QuickFindActivitiesQuery';

// TODO: we could use partials instead of fragments, similarly to how it's done in search-page
export const quickFindRecentActivitiesQuery = `
  query ${operationName}(
    $filteredProducts: [String!]
    $filteredObjectTypes: [String!]
    $itemsLimit: Int!
    $filteredRootContainerIds: [ID!]
  ) {
    activity {
      myActivity {
        all(
          filter: {
            arguments: {
              products: $filteredProducts
              objectTypes: $filteredObjectTypes
            }
            rootContainerIds: $filteredRootContainerIds
          }
          first: $itemsLimit
        ) {
          __typename
          edges {
            __typename
            node {
              __typename
              object {
                __typename
                id
                type
                product
                data {
                  __typename
                  ...ConfluencePageFragment
                  ...ConfluenceBlogPostFragment
                  ...ConfluenceWhiteboardFragment
                  ...AtlasProjectFragment
                  ...AtlasGoalFragment
                  ...JiraIssueFragment
                }
              }
              event {
                __typename
                eventType
                timestamp
              }
            }
          }
        }
      }
    }
  }
  ${ConfluencePageFragment}
  ${ConfluenceBlogPostFragment}
  ${ConfluenceWhiteboardFragment}
  ${AtlasProjectFragment}
  ${AtlasGoalFragment}
  ${JiraIssueFragment}
`;
