import { type ReactNode } from 'react';

/**
 * Backend experiment types
 */

// BackendExperiment includes both single-layer and multi-layer experiments.
export type BackendExperiment =
	| {
			experimentId: string;
			shadowExperimentId: string;
			controlId?: string; // TODO: remove after switching to multi-layer experiments
			abTestId?: string; // TODO: remove after switching to multi-layer experiments
			experimentLayers?: ExperimentLayer[]; // TODO: make non-optional after switching to multi-layer experiments
	  }
	| undefined;

export type ExperimentLayer = {
	name: string;
	layerId: string | null;
	shadowId: string | null;
	controlId: string | null;
	abTestId: string | null;
};

export type MultiLayerBackendExperiment = {
	experimentId: string;
	shadowExperimentId: string;
	experimentLayers: ExperimentLayer[];
};

export type BackendExperimentContextProps = {
	children: ReactNode;
};

/**
 * Frontend experiment types
 */

type FrontendExperiment =
	| {
			// Determine whether to serve the experiment or not
			serveExperiment: boolean;
			// The name of the statsig experiment
			abTestId: string;
			// The name of the group the user is enrolled in. Will either be the experimentId or the controlId
			cohort: string;
	  }
	| undefined;

export type FrontendExperiments =
	| {
			[key: string]: FrontendExperiment;
	  }
	| undefined;

export type FrontendExperimentProps = {
	// The name of the statsig experiment
	abTestId: string;
};

/**
 * Mutual exclusive experiment types
 */

export type MutuallyExclusiveExperiment = {
	frontendExperiment: FrontendExperiment;
	backendExperiment: BackendExperiment;
};

export enum Cohort {
	Default = 'not-enrolled',
	Control = 'control',
	Test = 'test',
}
